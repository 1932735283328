const TARGET_LANGUAGE = process.env.GATSBY_TARGET_LANGUAGE || "en";

const SITE_URL = process.env.GATSBY_SITE_URL || "https://xltools.net";

const locales = {
  en: {
    default: true,
    path: "",
    regex: "/en/",
    locale: "en-US",
    dateFormat: "DD/MM/YYYY",
    siteLanguage: "en",
    text: "English (US)",
    ogLanguage: "en_US",
    defaultTitle: "Using i18n with Gatsby",
    defaultDescription: "Gatsby example site using MDX and dependency-free i18n",
  },
  ru: {
    default: true,
    path: "",
    regex: "/ru/",
    locale: "ru-RU",
    dateFormat: "DD.MM.YYYY",
    siteLanguage: "ru",
    text: "Русский (RU)",
    ogLanguage: "ru_RU",
    defaultTitle: "i18n mit Gatsby nutzen",
    defaultDescription: "Gatsby Beispielseite, die MDX und i18n (frei von dependencies) nutzt",
  },
};

module.exports = {
  SITE_URL,
  TARGET_LANGUAGE,
  locales,
};
