/* eslint-disable react/prop-types */
import { createContext, useEffect, useState } from "react";

import { YMInitializer } from "react-yandex-metrika/src";
import i18next from "../../i18n";
// eslint-disable-next-line import/no-cycle
import { useWindowSize } from "../../helpers";
import { isSaleCompany, isPromoBanner } from "../../constants";
import { TARGET_LANGUAGE } from "../../../config/i18n";

const LocaleContext = createContext({
  localePath: "",
});

const YM_ACCOUNTS = { ru: 98321419, en: 22871677 };

const Layout = ({
  children,
  pageContext: { locale, siteLanguage, localePath },
}) => {
  useEffect(() => {
    i18next.changeLanguage(siteLanguage);
  }, [siteLanguage]);

  const [isCookiesBanner, setCookiesBanner] = useState(true);

  const mobile = useWindowSize();
  const mobileOnly = useWindowSize(550);

  return (
    <>
      <LocaleContext.Provider
        value={{
          locale,
          siteLanguage,
          localePath,
          isMobile: mobile,
          isMobileOnly: mobileOnly,
          isSaleCompany,
          isPromoBanner,
          isCookiesBanner,
          setCookiesBanner,
        }}
      >
        <>
          {children}
          <YMInitializer
            accounts={[YM_ACCOUNTS[TARGET_LANGUAGE]]}
            options={{
              webvisor: true,
              defer: true,
              clickmap: true,
            }}
          />
        </>
      </LocaleContext.Provider>
    </>
  );
};

export { Layout, LocaleContext };
